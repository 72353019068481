<template>
  <div>
    <h2 class="faledia-caption">Verstehensorientierte Erarbeitung</h2>
    <p>
      Wie bei anderen mathematischen Themen ist es notwendig, dass auch das
      schriftliche Rechnen verständnisbasiert erarbeitet wird. Denn sonst
      „besteht potenziell die Gefahr einer vorschnellen Abkopplung von
      Einsichtsprozessen zugunsten einer verfrühten Geläufigkeitsschulung und
      auf Kosten des Verstehens“ (Krauthausen, 2018, S. 91). Lediglich Übungen
      einzusetzen, die darauf abzielen, ein schriftliches Rechenverfahren
      auszuführen, birgt die Problematik, dass das Verständnis dafür, warum
      dieser Algorithmus funktioniert, ausbleibt. „Wie in allen Bereichen der
      Mathematik führt fehlendes Verständnis spätestens in unbekannten Kontexten
      oder in komplexeren Zusammenhängen zu Schwierigkeiten und Fehlern“ (Selter
      & Zannetin, 2018, S. 86). Das bedeutet nicht, dass sich hinreichend viele
      Übungen anschließen müssen, deren Einsatz die sichere Beherrschung des
      Verfahrens anzielen.
    </p>
    <p>
      Somit bietet es sich an, die schriftlichen Rechenverfahren aus den
      bekannten halbschriftlichen Strategien (siehe dazu auch
      <a href="/zahlenrechnen">Zahlenrechnen</a>) herzuleiten (Padberg & Benz
      2021, S. 248). Dabei muss der Lehrperson einerseits bewusst sein, welche
      halbschriftliche Strategie sich für das jeweilige Verfahren anbietet, denn
      nicht jede der halbschriftlichen Strategien lässt sich mit dem
      schriftlichen Verfahren in Beziehung setzen. Andererseits werden
      Zusammenhänge von Kindern in der Regel nicht einfach ‚entdeckt‘, sondern
      müssen gezielt angeregt und mit den Kindern gemeinschaftlich und in
      Bezugnahme auf deren Vorkenntnisse erarbeitet werden (Selter & Zannetin,
      2018, S. 91).
    </p>
    <p>
      Im Folgenden sollen Sie sich für die Addition überlegen, welche
      halbschriftliche Strategie sich anbietet, um das schriftliche Verfahren
      mit Kindern verständnisbasiert zu erarbeiten.
    </p>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :image="LBSTStempeln[0].image"
        :audio="LBSTStempeln[0].audio"
        :elements="LBSTStempeln[0].elements"
      >
        <template v-slot:alternative-content>
          <p>
            Der schriftliche Additionsalgorithmus lässt sich also mit den
            Kindern gemeinsam aus der Strategie stellenweise herleiten. Dafür
            können Sie anregen, dass Gemeinsamkeiten und Unterschieden
            reflektiert werden. Gemeinsamkeiten sind beispielsweise, dass jede
            Stelle für sich betrachtet und verrechnet wird. Am Ende steht
            dennoch immer das Gesamtergebnis. Auch das Übertragen von je 10
            Einheiten einer Stelle in die nächste Stelle wird in beiden
            Vorgehensweisen betrachtet. Beim stellenweisen Rechnen steht dies
            als Zahlganzheit in den zu verrechnenden Zwischensummen. Wird hier
            visuell am Zahlenbild erklärt, so kann dies direkt mit dem
            Bündelungsgedanken verknüpft werden. Beim schriftlichen Rechnen wird
            der Übertrag direkt mit der Übertragseins in der nächsten Stelle
            notiert.
          </p>
          <p>
            Unterschiede bestehen zum Beispiel in der Reihenfolge der Rechnung.
            Während beim halbschriftlichen Rechnen in der Regel mit dem höchsten
            Stellenwert begonnen wird, wird beim schriftlichen
            Additionsalgorithmus stets mit den Einern begonnen. Auch die Art der
            Notation unterscheidet sich. Halbschriftlich erfolgt eine
            horizontale Notation (wobei auch hier Teilrechnungen stellengerecht
            untereinander notiert werden). Beim schriftlichen Addieren werden
            die zu verrechnenden Zahlen stellengerecht untereinander notiert und
            dann vertikal betrachtet und die Ziffern der jeweiligen Stelle
            miteinander verrechnet. Auch das Betrachten der Ziffern beim
            schriftlichen Rechnen unterscheidet sich zum halbschriftlichen
            Rechnen, bei dem stets die Zahlen in den Blick genommen werden, auch
            wenn dies stellenweise geschieht.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>
    <p>
      Nun haben Sie nachvollziehen können, welche halbschriftliche Strategie
      sich anbietet, um daraus den schriftlichen Additionsalgorithmus
      herzuleiten. Achten Sie daher immer darauf, dass Sie sich Gemeinsamkeiten,
      Unterschiede und konkretes Vorgehen auch erklären lassen. Das dient zum
      einen Ihnen als Anhaltspunkt, ob ein Kind den Algorithmus verständig
      nutzt, andererseits regt dies auch Reflexionen des Kindes an.
      Grundsätzlich bietet sich bei den meisten schriftlichen Verfahren die
      entsprechende stellenweise Strategie an, um daran anzuknüpfen – eine
      Ausnahme bildet die Division, bei der schrittweise immer das größtmögliche
      Vielfache gesucht wird, durch das dividiert werden kann (Selter &
      Zannetin, 2018, S. 89).
    </p>
    <AppLiteraturZR />
    <AppBottomNavZR
      next="/ziffernrechnen/hintergrundwissen/flexibles-rechnen"
      back="/ziffernrechnen/hintergrundwissen/verfahrensweisen"
    />
  </div>
</template>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
import AppLiteraturZR from "@/common/AppLiteraturZR";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";

import LBST_Stempeln_Herleitung from "@/components/faledia/seiten/ziffernrechnen/LBST/LBST_Stempeln_Herleitung";

export default {
  components: {
    AppBottomNavZR,
    AppLiteraturZR,
    LernbausteinStempeln,
  },
  data: () => ({
    LBSTStempeln: [LBST_Stempeln_Herleitung],
  }),
};
</script>
