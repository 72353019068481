<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>

<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Fast, M. (2017).
                <i
                  >Wie Kinder addieren und subtrahieren. Längsschnittliche
                  Analysen in der Primarstufe.</i
                >
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Gerster, H. (2015).
                <i
                  >Schülerfehler bei schriftlichen rechenverfahren. Diagnose und
                  Therapie. </i
                >Münster: WTM-Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Greiler-Zauchner, M. (2022).
                <i
                  >Rechenwege für die Multiplikation und ihre Umsetzung.
                  Einsicht in operative Beziehungen erlangen und aufgabenadäquat
                  anwenden.</i
                >
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Krauthausen, G. (2018).
                <i>Einführung in die Mathematikdidaktik-Grundschule. </i>
                Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F., Benz, C. (2011).
                <i
                  >Didaktik der Arithmetik. Für Lehrerausbildung und
                  Lehrerfortbildung.</i
                >
                Heidelberg: Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F., Benz, C., (2021).
                <i>Didaktik der Arithmetik. fundiert, vielseitig, praxisnah.</i>
                Berlin/Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Radatz, H. Schipper, W. Dröge, R., Ebeling, A. (1999).
                <i>Handbuch für den Mathematikunterricht. 3. Schuljahr.</i>
                Soest: Schroedel.
              </td>
            </tr>
            <tr>
              <td>
                Rathgeb-Schnierer, Elisabeth (2011): Warum noch rechnen, wenn
                ich die Lösung sehen kann? Hintergründe zur Förderung flexibler
                Rechenkompetenzen bei Grundschulkindern. In: R. Haug, L.
                Holzäpfel (Hrsg.):<i>
                  Beiträge zur 45. Jahrestagung der Gesellschaft für Didaktik
                  der Mathematik vom 21.02.2011 bis 25.02.2011 in Freiburg.</i
                >
                WTM-Verlag, S. 15–22.
              </td>
            </tr>
            <tr>
              <td>
                Selter, Ch. (1999):
                <i>
                  Flexibles Rechnen statt Normierung auf Normalverfahren! Die
                  Grundschulzeitschrift,
                </i>
                13(125), 6–11.
              </td>
            </tr>
            <tr>
              <td>
                Selter, C. (2000). Vorgehensweisen von Grundschüler(inne)n bei
                Aufgaben zur Addition und Subtraktion im Zahlenraum bis 1000.
                <i>Journal für Mathematik-Didaktik,</i> 21(3–4), 227–258.
                <a href="https://doi.org/10.1007/BF03338920"
                  >https://doi.org/10.1007/BF03338920</a
                >
              </td>
            </tr>
            <tr>
              <td>
                Selter, Ch., Zannetin, E. (2018).<i>
                  Mathematik unterrichten in der Grundschule. Inhalte -
                  Leitideen - Beispiele.</i
                >
                Seelze: Kallmeyer.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  components: {
    AppButtonCollapse,
  },
};
</script>
