function createObject() {
  let example = {
    id: "LBST_ZR_Stempeln_Herleitung",
    workOrder: "Betrachten Sie folgende Additionsaufgabe: ",
    image: require("@/assets/zr/Stempeln_Herleitung/01.png"),
    elements: [
      [
        {
          question:
            "Welche halbschriftliche Rechnung bietet sich an, um den schriftlichen Additionsalgorithmus herzuleiten? Und warum bietet sich diese Strategie an?",
          answers: [
            {
              correct: false,
              text: `Das <strong>schrittweise Rechnen</strong> ist durch das Aufteilen eines Summanden in Schritte ähnlich zum Vorgehen des schriftlichen Additionsalgorithmus und knüpft somit an Vorerfahrungen der Lernenden an. <br /> Strategie schrittweise:`,
              response:
                "Bei der Strategie schrittweise wird ein Summand sukzessive, in Schritten, zu dem anderen Summanden hinzuaddiert. Eine Nähe zum Vorgehen beim schriftlichen Additionsalgorithmus ist somit nicht gegeben.",
              image: require("@/assets/zr/Stempeln_Herleitung/02.png"),
            },
            {
              correct: true,
              text:
                "Das <strong>stellenweise Rechnen</strong> ist durch das Fokussieren und Verrechnen der einzelnen Stellenwerte ähnlich zum Vorgehen des schriftlichen Additionsalgorithmus und knüpft somit an Vorerfahrungen der Lernenden an. <br /> Strategie stellenweise: ",
              response:
                "Prima! Genau wie beim schriftlichen Additionsalgorithmus, werden bei der stellenweisen Strategie die Stellenwerte einzeln betrachtet und miteinander verrechnet. Durch die Nähe der beiden Vorgehensweisen kann somit an das Vorwissen der Kinder angeknüpft werden.",
              image: require("@/assets/zr/Stempeln_Herleitung/03.png"),
            },
            {
              correct: false,
              text:
                "Die <strong>Hilfsaufgabe</strong> lässt zu, dass Ergebnisse des schriftlichen Algorithmus am leichtesten überprüft werden können und knüpft daher an Vorerfahrungen der Lernenden an. <br /> Strategie Hilfsaufgabe: ",
              response:
                "Bei der Hilfsaufgabe wird sich eine andere, leichter zu berechnende Aufgabe gesucht, mit der das Ergebnis näherungsweise bestimmt wird. In einem zweiten Schritt, wird das erhaltene Ergebnis entsprechend um den Wert, der zu viel oder zu wenig berechnet wurde, verändert, um somit auf das Ergebnis der Ausgangsaufgabe zu gelangen. Eine Nähe zum Vorgehen beim schriftlichen Additionsalgorithmus ist somit nicht gegeben. Daher bietet es sich nicht an, um hier an Vorerfahrungen anzuknüpfen und den Algorithmus daraus abzuleiten.",
              image: require("@/assets/zr/Stempeln_Herleitung/04.png"),
            },
          ],
        },
      ],
      [
        {
          question:
            "Welche Visualisierung bietet sich zum Verknüpfen der halbschriftlichen Strategie und des Algorithmus an? Und warum bietet sich diese Visualisierung an?",
          answers: [
            {
              correct: true,
              text:
                "Mit dem <strong>Zahlbild</strong> lassen sich sowohl die stellenweisen Rechenschritte (halbschriftlich und schriftlich) nachvollziehbar darstellen und begründen. Außerdem kann der Übertrag durch Bündelungen verständlich gemacht werden. <br /> Zahlbild: ",
              response:
                "Genau! Anfangs bietet es sich an, dass Kinder visuell unterstützt die Gemeinsamkeiten des halbschriftlichen und schriftlichen Vorgehens erklären. Dafür bietet es sich an entweder ein Zahlbild zu nutzen und Stelle für Stelle zu betrachten. Auch handelnd am Würfelmaterial können Gemeinsamkeiten aufgedeckt werden. Woher der Übertrag kommt, kann anhand des Bündelns nachvollzogen und verständlich gemacht werden. Eine sprachliche Begleitung ist dabei jeweils wichtig.",
              image: require("@/assets/zr/Stempeln_Herleitung/05.png"),
            },
            {
              correct: false,
              text:
                "Der <strong>Zahlenstrahl</strong> bildet das Zerlegen des einen Summanden ab. Daran kann ein Vorgehen Stelle für Stelle deutlich gemacht werden. <br /> Rechenstrich: ",
              response:
                "Zwar kann ein schrittweises Hinzuaddieren eines Summanden am Zahlenstrahl dargestellt werden, allerdings wird immer nur ein Summand in Schritten zu dem anderen addiert. Unten am Zahlenstrahl sind die Zwischenergebnisse abzulesen. Ein Denken, bei dem Stelle für Stelle vorgegangen wird, ist hier nicht mit zu visualisieren.",
              image: require("@/assets/zr/Stempeln_Herleitung/06.png"),
            },
            {
              correct: false,
              text: "Keine Visualisierung",
              response:
                "Gerade zu Beginn ist es wichtig, dass Kinder Vorstellungen über ihr mathematisches Handeln aufbauen können. Wird die halbschriftliche Strategie stellenweise und das schriftliche Addieren miteinander in Beziehung gesetzt, so kann ein durch Visualisierung gestütztes Nachvollziehen dabei unterstützen zum einen Vorstellungen zu vertiefen und zum anderen auch Verständnis – zum Beispiel, was ein Übertrag bedeutet – aufbauen.",
            },
          ],
        },
      ],
    ],
  };

  return example;
}

export default createObject();
