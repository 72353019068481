<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";

export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Ziffernrechnen",
          to: "/ziffernrechnen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/ziffernrechnen/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Verfahrensweisen",
          to: "/ziffernrechnen/hintergrundwissen/verfahrensweisen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Verstehensorientierte Erarbeitung",
          to:
            "/ziffernrechnen/hintergrundwissen/verstehensorientierte-erarbeitung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Flexibles Rechnen",
          to: "/ziffernrechnen/hintergrundwissen/flexibles-rechnen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/ziffernrechnen/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/ziffernrechnen/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/ziffernrechnen/check",
        },
      ],
    };
  },
};
</script>
