<template>
  <!-- @click="onClick" -->
  <v-btn v-bind="$attrs" v-on="$listeners">
    <v-icon>mdi-progress-check</v-icon>
    {{ text }}
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "AppButtonCheck",

  props: {
    text: { String, default: "Überprüfen" },
    // onClick: {type: Function, required: true},
  },
};
</script>
