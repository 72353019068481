<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600" style="z-index: 1335;">
        <v-card>
        <v-card-title class="text-h5">
          Erläuterung des Beispiels
        </v-card-title>
        <v-card-text>
          <p>Falls Ihnen das Beispiel Kopfzerbrechen bereitet, können Sie sich dieses auch in einer alternativen Darstellung ansehen.
          Diese Option bleibt Ihnen ab jetzt erhalten, bis Sie "Reset" betätigen.</p>
          
          Möchten Sie sich die Erläuterung ansehen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="optionYes"
          >
            Ja
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="optionNo"
          >
            Nein
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="optionLater"
          >
            Lieber später
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppHelper from "@/common/AppHelper";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    trials: Number,
    maxTrials: {Number, default: 3},
    exerciseId: String,
  },
  computed: {
    reachedThreshold: function() {
      // show dialog, if the threshold of trials is reached
      return (
        this.trials === this.maxTrials
      );
    }
  },
  watch: {
    reachedThreshold: function () {
      if(this.reachedThreshold) {
        this.dialog = true;
        AppHelper.trackMatomoEvent(
          this,
          "AppDialogAlternative",
          "id:70; Threshold an Versuchen erreicht. Dialog geöffnet.; LBST ID: " +
          this.exerciseId +
          ";",
          "AppDialogAlternative"
        );
      }
    }
  },
  methods: {
    optionYes: function () {
      let open = true;
      this.$emit('reached-trials-threshold', open);
      this.dialog = false;
      AppHelper.trackMatomoEvent(
        this,
        "AppDialogAlternative",
        "id:71; Dialog mit Option Ja geschlossen.; LBST ID: " +
        this.exerciseId +
        ";",
        "AppDialogAlternative",
      );
    },

    optionLater: function () {
      let open = false;
      this.$emit('reached-trials-threshold', open);
      this.dialog = false;
      AppHelper.trackMatomoEvent(
        this,
        "AppDialogAlternative",
        "id:72; Dialog mit Option Später geschlossen.; LBST ID: " +
        this.exerciseId +
        ";",
        "AppDialogAlternative",
      );
    },

    optionNo: function () {
      this.dialog = false;
      AppHelper.trackMatomoEvent(
        this,
        "AppDialogAlternative",
        "id:73; Dialog mit Option Nein geschlossen.; LBST ID: " +
        this.exerciseId +
        ";",
        "AppDialogAlternative",
      );
    },
  },
};
</script>

<style scoped>
</style>