<template>
  <v-row no-gutters>
    <AppDialogAlternative
      v-bind:trials="trials"
      :maxTrials="maxTrials"
      v-on:reached-trials-threshold="showAdditionalContent"
      v-show="false"
      :exerciseId="this.id"
    />
    <v-col :class="id === exerciseId ? 'mt-2' : 'pt-0'">
      <AppButtonCollapse
        messageHidden="Erläuterung anzeigen"
        messageNotHidden="Erläuterung verbergen"
        :collapseId="this.id"
      >
        <slot name="alternative-content"></slot>
        <v-row dense>
          <v-col>
            <v-btn
              :disabled="disabled"
              block
              :text="chosen === 'Up' ? false : true"
              :outlined="chosen === 'Up' ? false : true"
              color="primary"
              @click="thumbUp"
              ><v-icon left>mdi-thumb-up</v-icon> Erläuterungsangebot war
              hilfreich</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :disabled="disabled"
              block
              :text="chosen === 'Down' ? false : true"
              :outlined="chosen === 'Down' ? false : true"
              color="primary"
              @click="thumbDown"
              ><v-icon left>mdi-thumb-down</v-icon> Erläuterungsangebot war
              nicht hilfreich</v-btn
            >
          </v-col>
        </v-row>
      </AppButtonCollapse>
    </v-col>
  </v-row>
</template>

<script>
import AppDialogAlternative from "@/common/AppDialogAlternative";
import AppButtonCollapse from "@/common/AppButtonCollapse";

import AppHelper from "@/common/AppHelper";
import EventBus from "@/common/EventBus";

export default {
  components: {
    AppDialogAlternative,
    AppButtonCollapse,
  },

  props: {
    id: { type: String, required: true },
    exerciseId: { type: String, required: true },
    trials: { type: Number },
    maxTrials: { type: Number, default: 3 },
    solved: { type: Boolean, default: false },
    reset: { type: Boolean, default: false },
  },

  data() {
    return {
      disabled: false,
      chosen: "",
    };
  },

  watch: {
    solved: function() {
      if (this.solved) {
        this.showAdditionalContent(false);
      }
    },

    reset: function() {
      if (this.reset) {
        this.hideAdditionalContent();
        this.disabled = false;
        this.chosen = "";
        this.$emit("reset");
      }
    },
  },

  methods: {
    thumbUp: function() {
      AppHelper.trackMatomoEvent(
        this,
        "AppAlternativeContent",
        "id:75; I-Alternative mit Daumen hoch bewertet; LBST ID: " +
          this.id +
          ";",
        "AppAlternativeContent"
      );

      this.disabled = true;
      this.chosen = "Up";
    },

    thumbDown: function() {
      AppHelper.trackMatomoEvent(
        this,
        "AppAlternativeContent",
        "id:76; I-Alternative mit Daumen runter bewertet; LBST ID: " +
          this.id +
          ";",
        "AppAlternativeContent"
      );

      this.disabled = true;
      this.chosen = "Down";
    },

    showAdditionalContent: function(open) {
      EventBus.$emit(this.id);
      if (open) {
        EventBus.$emit(this.id + "Open");
      }
    },

    hideAdditionalContent: function() {
      EventBus.$emit(this.id + "Reset");
    },
  },
};
</script>

<style></style>
